var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-widget',{attrs:{"title":"Servicios","simpleToolbar":true,"icon":"calendar_month","toolbarColor":"secondary","contentBg":"pa-0"}},[(_vm.isEditable)?_c('div',{attrs:{"slot":"widget-header-action"},slot:"widget-header-action"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","loading":_vm.hisLoading},on:{"click":_vm.addProduct}},on),[_c('v-icon',[_vm._v("add")])],1)]}}],null,false,1244986080)},[_c('span',[_vm._v(_vm._s(_vm.L('Agregar Servicio')))])])],1):_vm._e(),_c('div',{staticClass:"mb-4",attrs:{"slot":"widget-content"},slot:"widget-content"},[_c('v-data-table',{staticClass:"elevation-0 striped products-details",attrs:{"headers":_vm.columns,"items":_vm.list,"options":_vm.options,"loading":_vm.loading,"loading-text":"Cargando... Por favor Espere","disable-pagination":_vm.totalCount < 11,"hide-default-footer":_vm.totalCount < 11,"dense":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.product",fn:function(ref){
var item = ref.item;
return [_c('v-autocomplete',{attrs:{"item-text":"name","item-value":"id","items":_vm.services,"readonly":_vm.booking.invoiced,"small-chips":"","autocomplete":"off","no-data-text":"Sin registros disponibles","hide-details":"","dense":""},on:{"change":function($event){return _vm.changeProduct(item)}},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-list-item-avatar',{staticClass:"my-0"},[_c('v-avatar',{attrs:{"color":"primary","size":"30"}},[_c('span',{staticClass:"white--text caption"},[_vm._v(_vm._s(data.item.code))])])],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(data.item.name)}}),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(data.item.product_type_name)}})],1)]}},{key:"selection",fn:function(data){return [_c('v-chip',{attrs:{"small":"","pill":""}},[_c('v-avatar',{attrs:{"left":"","color":"primary"}},[_c('span',{staticClass:"white--text caption"},[_vm._v(_vm._s(data.item.code))])]),_vm._v(" "+_vm._s(data.item.name)+" ")],1)]}}],null,true),model:{value:(item.product_id),callback:function ($$v) {_vm.$set(item, "product_id", $$v)},expression:"item.product_id"}})]}},{key:"item.schedule_id",fn:function(ref){
var item = ref.item;
return [_c('v-autocomplete',{attrs:{"item-text":"name","item-value":"id","items":_vm.schedules,"readonly":_vm.booking.invoiced,"small-chips":"","hide-details":"","dense":""},on:{"change":function($event){return _vm.changeSchedule(item)}},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(data.item.name)}}),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(data.item.hour_range)}})],1)]}}],null,true),model:{value:(item.schedule_id),callback:function ($$v) {_vm.$set(item, "schedule_id", $$v)},expression:"item.schedule_id"}})]}},{key:"item.schedule",fn:function(ref){
var item = ref.item;
return [(!_vm.booking.invoiced)?_c('v-autocomplete',{attrs:{"items":_vm.getIntervals(item),"small-chips":"","hide-details":"","dense":""},model:{value:(item.schedule),callback:function ($$v) {_vm.$set(item, "schedule", $$v)},expression:"item.schedule"}}):_c('v-chip',{attrs:{"small":""}},[_vm._v(_vm._s(item.schedule))])]}},{key:"item.duration",fn:function(ref){
var item = ref.item;
return [(item.product.product_type_id != _vm.productCA)?_c('v-text-field',{staticClass:"detail-taxes",staticStyle:{"max-width":"130px"},attrs:{"type":"number","dense":"","hide-details":""},on:{"focus":_vm.onFocus,"input":function($event){return _vm.setPrice(item)}},model:{value:(item.duration),callback:function ($$v) {_vm.$set(item, "duration", $$v)},expression:"item.duration"}}):_c('span',[_vm._v(_vm._s(item.duration))])]}},{key:"item.price_wt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.currency_sign)+" "+_vm._s(_vm.formatNumber(item.price_wt,2))+" ")]}},{key:"item.discounts",fn:function(ref){
var item = ref.item;
return [(!_vm.booking.invoiced)?_c('v-text-field',{staticClass:"detail-taxes",staticStyle:{"max-width":"190px"},attrs:{"type":"number","dense":"","hide-details":""},on:{"focus":_vm.onFocus,"change":function($event){return _vm.setPrice(item)}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-select',{staticStyle:{"width":"50px"},attrs:{"items":_vm.discount_types,"dense":"","hide-details":"","hide-selected":"","item-text":"name","item-value":"id"},on:{"change":function($event){return _vm.setPrice(item)}},model:{value:(item.discount_type),callback:function ($$v) {_vm.$set(item, "discount_type", $$v)},expression:"item.discount_type"}})]},proxy:true}],null,true),model:{value:(item.discounts),callback:function ($$v) {_vm.$set(item, "discounts", $$v)},expression:"item.discounts"}}):_c('span',[_vm._v(_vm._s(item.discount_type == 'amount' ? _vm.currency_sign : '')+" "+_vm._s(item.discounts)+" "+_vm._s(item.discount_type == 'amount' ? '' : '%'))])]}},{key:"item.total_wt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.currency_sign)+" "+_vm._s(_vm.formatNumber(item.total_wt,2))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.isEditable && !item.finished && !_vm.booking.invoiced)?_c('v-icon',{attrs:{"color":"error"},on:{"click":function($event){return _vm.deleteProduct(index)}}},[_vm._v(" delete ")]):_vm._e()]}},{key:"no-data",fn:function(){return [_c('v-row',{staticClass:"d-flex flex-wrap text-center justify-space-around",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('v-btn',{staticStyle:{"height":"100px","width":"100px"},attrs:{"icon":"","large":"","loading":_vm.hisLoading},on:{"click":_vm.addProduct}},[_c('v-icon',{attrs:{"size":"100px","color":"success"}},[_vm._v("add_box")])],1)],1),_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('h4',[_vm._v("Agregar Servicio")])]),_c('v-col',{staticStyle:{"font-size":"16px"},attrs:{"cols":"12","lg":"12"}})],1)]},proxy:true}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }